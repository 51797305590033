import { Module, ActionContext } from "vuex";
import { CardData, FetchReportFilesResponse, FetchReportStatusHistoryResponse, ReportFilter, ReportResponse, StepCard } from "@/types/Report"
import { ReportService, DemandService } from "@/services";
import { RootState, store } from ".";
import { AdditionalQuestion, IndividuellerKaskoSchaden, LiabilityDamageReport, ComprehensiveDamageReport, OnlineGutachten, Report, SmartKVReport, StatusReport, Step, OnlineKv, DamageReport, RepairConfirmation, FloorCheck, WaybillCheck, PreCheckGoReport } from "@/classes/report";
import { useRouter } from "vue-router";
import moment from "moment";
import { Demand } from "@/classes/demand";

export type ReportState = {
  [key: string]: any;
  report?: Report,
  steps: Step[],
  reports: ReportResponse;
  preCheckReports: PreCheckGoReport[];
  preCheckReport?: PreCheckGoReport;
  reportFilter?: ReportFilter,
  loadingReport: boolean,
  error: string,
  currentReportType: string,
  reportFiles: Array<FetchReportFilesResponse>
  reportStatusHistory: Array<FetchReportStatusHistoryResponse>
  demandList: Array<Demand>,
  recentReports?: Array<Report>,
  additionalQuestions?: Array<AdditionalQuestion>,

  loadingAssociatedWaybills: boolean;
  associatedWaybills?: Array<WaybillCheck>;
}

const report: Module<ReportState, RootState> = {
  namespaced: true,
  state: () => ({
    report: undefined,
    steps: [],
    preCheckReports: [],
    preCheckReport: undefined,
    reports: { currentPage: 0, totalPages: 0, foundSQLReports: [], totalResults: 0 },
    reportFilter: {
      page: 0,
      report_type_table: "All",
      date_from: undefined,
      date_to: undefined,
      status: "All",
      organization: "All",
      search: "",
      hasDamage: false,
      searchObject: {
        id: "-1",
        name: "",
        values: []
      },
      location: "-1"
    },
    currentReportType: "",
    loadingReport: false,
    error: "",
    reportFiles: [],
    reportStatusHistory: [],
    demandList: [],
    recentReports: undefined,
    additionalQuestions: [],
    loadingAssociatedWaybills: false,
    associatedWaybills: [],
  }),
  getters: {
    filter(state: ReportState) {
      return state.reportFilter
    },
    reportType(state: ReportState): Report | undefined {
      return state.report;
    },
    showField: (state: ReportState) => (data: CardData): boolean => {
      const router = useRouter()

      const results = []

      if (data.dependsOn) {
        data.dependsOn.forEach(async dependency => {
          if (state.report != undefined && state.report![dependency.field] == dependency.condition) {
            results.push(true)
          } else {
            results.push(false)
          }
        })
      } else {
        results.push(true)
      }

      const routerName = router.currentRoute.value.name as string
      if (routerName.toLowerCase().includes('overview') && results.filter((r) => r == false).length == 0) {
        if (data.overviewDependency && data.isOverview!) {
          const showOverviewWithDependency = Array<boolean>();

          data.overviewDependency.forEach(async dependency => {
            if (state.report != undefined && state.report![dependency.field] == dependency.condition) {
              showOverviewWithDependency.push(true)
            } else {
              showOverviewWithDependency.push(false)
            }
          })
          return showOverviewWithDependency.filter((r) => r == false).length == 0;
        } else {
          return data.isOverview!
        }
      }
      return results.filter((r) => r == false).length == 0
    },
    showCard: (state: ReportState) => (data: StepCard): boolean => {
      const results = []
      if (data.dependsOn) {
        data.dependsOn.forEach(async dependency => {
          if (state.report != undefined && state.report![dependency.field] == dependency.condition) {
            results.push(true)
          } else {
            results.push(false)
          }
        })
      } else {
        results.push(true)
      }
      return results.filter((r) => r == false).length == 0
    },
    reportFiles: (state: ReportState): Array<FetchReportFilesResponse> => {
      return state.reportFiles;
    },
    reportStatusHistory: (state: ReportState): Array<FetchReportStatusHistoryResponse> => {

      const sortedArray = state.reportStatusHistory.sort((a, b) => (moment(a.changeDate).format('YYYYMMDDHHmmss') as any) > (moment(b.changeDate).format('YYYYMMDDHHmmss') as any) ? -1 : 1)

      return sortedArray;
    },
    getReportValueByType: (state: ReportState) => (propertyName: string) => {
      return state.report![propertyName];
    },
    loadingReport: (state: ReportState) => {
      return state.loadingReport;
    }
  },
  mutations: {
    setReports(state: ReportState, reportResponse: ReportResponse) {
      state.reports = reportResponse;
    },
    setPreCheckReports(state: ReportState, preCheckReports: PreCheckGoReport[]) {
  

      state.preCheckReports = preCheckReports;
    },
    setPreCheckReport(state: ReportState, preCheckReport: PreCheckGoReport) {
      state.preCheckReport = preCheckReport;
      state.loadingReport = false;
    },
    setReportFilter(state: ReportState, reportFilter: ReportFilter) {
      state.reportFilter = { ...state.reportFilter, ...reportFilter };
    },
    setReportValueByType(state: ReportState, options: { propertyName: string, value: string | number | boolean }) {
      const propertyName = options.propertyName;
      state.report![propertyName] = options.value
    },
    setReport(state: ReportState, report: Report) {
      state.report = report;
    },
    setReportFiles(state: ReportState, list: Array<FetchReportFilesResponse>) {
      state.reportFiles = list;
    },
    setCurrentPage(state: ReportState, page: number) {
      state.reportFilter!.page = page;
    },
    setOrganization(state: ReportState, brandingId: string) {
      state.reportFilter!.organization = brandingId;
    },
    setHasDamageFilter(state: ReportState, hasDamage: boolean) {
      state.reportFilter!.hasDamage = hasDamage;
    },
    resetFilter(state: ReportState, brandingId: string) {
      state.reportFilter = {
        page: 0,
        report_type_table: "All",
        date_from: undefined,
        date_to: undefined,
        status: "All",
        organization: brandingId,
        search: "",
        hasDamage: false,
        searchObject: {
          name: "",
          values: []
        }
      };
    },
    toggleLoadingReport(state: ReportState) {
      state.loadingReport = !state.loadingReport;
    },
    setDemandList(state: ReportState, demandList: Array<Demand>) {
      state.demandList = demandList;
    },
    setEmptyDemandList(state: ReportState) {
      state.demandList = [];
    },
    setDemandDemandedValueByType(state: ReportState, options: { propertyName: string, value: number }) {
      state.demandList.forEach((demand: Demand) => {
        if (demand.type == options.propertyName) {
          demand.demandedValue = options.value;
        }
      })
    },
    setDemandReceivedValueByType(state: ReportState, options: { propertyName: string, value: number }) {
      state.demandList.forEach((demand: Demand) => {
        demand.type == options.propertyName ? demand.receivedValue = options.value : "";
      })
    },
    setDemandRemarksByType(state: ReportState, options: { propertyName: string, value: string }) {
      state.demandList.forEach((demand: Demand) => {
        demand.type == options.propertyName ? demand.remarks = options.value : "";
      })
    },
    setDemandStatusByType(state: ReportState, options: { propertyName: string, value: string }) {
      state.demandList.forEach((demand: Demand) => {
        demand.type == options.propertyName ? demand.remarks = options.value : "";
      })
    },
    setRecentReports(state: ReportState, reports: Array<Report>) {
      state.recentReports = reports;
    },
    setAdditionalQuestions(state: ReportState, additionalQuestions: Array<AdditionalQuestion>) {
      state.additionalQuestions = additionalQuestions;
    },
    setAssociatedWaybills(state: ReportState, associatedWaybills: Array<WaybillCheck>) {
      state.associatedWaybills = associatedWaybills;
    }
  },
  actions: {
    async fetchReportById(context: ActionContext<ReportState, RootState>, options: { reportId: number, stepNum?: number }) {
      const [response, error] = await ReportService.getReportById(options.reportId);
      
      if (!error) {
        await context.dispatch("loadReportSteps", ({ reportType: response.reportType, stepNum: (options.stepNum) ? options.stepNum : 0 }));

        if (response.reportType.toLowerCase() == 'liabilitydamage') {
          context.commit('setReport', response as LiabilityDamageReport)
          await store.dispatch('vehicleModule/initModule', (options.stepNum) ? options.stepNum : 0);
          await context.dispatch('fetchDemandListById', options.reportId)
        } else if (response.reportType.toLowerCase() == 'comprehensivedamage') {
          context.commit('setReport', response as ComprehensiveDamageReport)
          await store.dispatch('vehicleModule/initModule', (options.stepNum) ? options.stepNum : 0);
          await context.dispatch('fetchDemandListById', options.reportId)
        }

        else if (response.reportType.toLowerCase() == 'statusreport') {
          context.commit('setReport', response as StatusReport)
        } else if (response.reportType.toLowerCase() == 'smartkv') {
          context.commit('setReport', response as SmartKVReport)
        } else if (response.reportType.toLowerCase() == 'individuellerkaskoschaden') {
          context.commit('setReport', response as IndividuellerKaskoSchaden)
        } else if (response.reportType.toLowerCase() == 'onlinegutachten') {
          context.commit('setReport', response as unknown as OnlineGutachten)
          await store.dispatch('vehicleModule/initModule', (options.stepNum) ? options.stepNum : 0);
        } else if (response.reportType.toLowerCase() == 'onlinekv') {
          context.commit('setReport', response as unknown as OnlineKv)
          await store.dispatch('vehicleModule/initModule', (options.stepNum) ? options.stepNum : 0);
        } else if (response.reportType.toLowerCase() == 'damagereport') {
          context.commit('setReport', response as DamageReport)
          await store.dispatch('vehicleModule/initModule', (options.stepNum) ? options.stepNum : 0);
        } else if (response.reportType.toLowerCase() == 'repairconfirmation') {
          context.commit("setReport", response as RepairConfirmation);
        } else if (response.reportType.toLowerCase() == 'floorcheck') {
          context.commit("setReport", response as FloorCheck);
        } else if (response.reportType.toLowerCase() == 'waybillcheck') {
          context.commit("setReport", response as WaybillCheck);
          await store.dispatch("vehicleModule/initModule", (options.stepNum) ? options.stepNum : 0);
        }
        else {
          context.commit('setReport', undefined);
        }
      } else {
        context.commit('setReport', undefined);
      }
    },
    async loadReportSteps(context: ActionContext<ReportState, RootState>, options: { reportType: string, stepNum: number }) {
      if (options.reportType.toLowerCase() == 'liabilitydamage') {
        const r = await import(
          "@/components/report-stepper/liability-damage"
        );
        context.state.steps = r.steps;
      }
      else if (options.reportType.toLowerCase() == 'comprehensivedamage') {
        const r = await import(
          "@/components/report-stepper/comprehensive-damage"
        );
        context.state.steps = r.steps;
      }
      else if (options.reportType.toLowerCase() == 'onlinegutachten') {
        const r = await import(
          "@/components/report-stepper/online-gutachten"
        );
        context.state.steps = r.steps;
      } else if (options.reportType.toLowerCase() == 'onlinekv') {
        const r = await import(
          "@/components/report-stepper/online-kv"
        );
        context.state.steps = r.steps;
      }
      if (options.stepNum && options.stepNum > 0) {
        for (let i = 0; i < context.state.steps.length; i++) {
          if (i < options.stepNum) context.state.steps[i].done = true;
          else context.state.steps[i].done = false;
        }
      }
    },
    async fetchReports(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await ReportService.fetchReport(context.getters.filter);
      if (!error) {
        context.commit("setReports", response)
      }
    },

    async fetchPreCheckReports(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await ReportService.fetchPreCheckReports();

      if (!error) {
        context.commit("setPreCheckReports", response)
      }
    },

    async filterPreCheckReports(context: ActionContext<ReportState, RootState>, payload: { reportId: string, vin: string }) {
      const { reportId: reportLicenseNumberParam } = payload;
      const reportLicenseNumber = reportLicenseNumberParam !== undefined ? reportLicenseNumberParam : "";
      const [response, error] = await ReportService.filterPreCheckReportsByLicenseNumber(reportLicenseNumber);

      if (!error) {
        context.commit("setPreCheckReports", response)
      }
    },

    async fetchPreCheckReportByRowKeyId(context: ActionContext<ReportState, RootState>, reportId: string) {
      const [response, error] = await ReportService.fetchPreCheckReportByRowKeyId(reportId);

      if (!error) {
        context.commit("setPreCheckReport", response)
      }
    },

    async setCurrentResultPage(context: ActionContext<ReportState, RootState>, page: number) {
      if (page == context.state.reports.currentPage) return;
      context.commit('setCurrentPage', page)
      await context.dispatch('fetchReports');
    },
    async refreshFilterResults(context: ActionContext<ReportState, RootState>) {
      context.commit('setCurrentPage', 0)
      await context.dispatch('fetchReports');
    },
    async refreshPreCheckFilterResults(context: ActionContext<ReportState, RootState>) {
      context.commit('setCurrentPage', 0)
      await context.dispatch('fetchPreCheckReports');
    },
    async createReport(context: ActionContext<ReportState, RootState>, options: { report: Report, userBrandingId: number }) {
      context.commit('toggleLoadingReport')
      options.report.status = "Wartend auf Zuweisung"
      options.report.branding_Id = options.userBrandingId;

      const [response, error] = await ReportService.createReport(options.report.reportType, options.report);
      if (!error) {
        if (options.report.reportType.toLowerCase() == 'liabilitydamage') {
          options.report.report_Id = response.id;
          context.commit("setReport", options.report);
          await context.dispatch("loadReportSteps", ({ reportType: options.report.reportType, stepNum: 0 }));
        } else {
          options.report.report_Id = response.id;
          context.commit("setReport", options.report);
          await context.dispatch("loadReportSteps", ({ reportType: options.report.reportType, stepNum: 0 }));
        }
        if (options.report.reportType == 'LiabilityDamage') {
          await store.dispatch('vehicleModule/initModule');
        } else if (options.report.reportType == 'ComprehensiveDamage') {
          await store.dispatch('vehicleModule/initModule');
        }
      }
    },
    async updateReport(context: ActionContext<ReportState, RootState>) {
      const [status, error] = await ReportService.updateReport(context.state.report!.reportType, context.state.report!);
      if (!error && status && context.state.report) {
        context.state.report.status = status;
      }
    },
    async fetchStatusHistory(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await ReportService.fetchReportStatusHistory(context.getters['reportType'].report_Id)
      if (!error) {
        context.state.reportStatusHistory = response;
      }
    },
    async fetchFilesForReport(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await ReportService.fetchFilesForReport(context.getters['reportType'].report_Id)
      if (error) {
        return;
      }

      console.log("fetched respone", response);
      context.commit('setReportFiles', response);
    },
    async uploadFileForReport(context: ActionContext<ReportState, RootState>, data: FormData) {
      const [response, error] = await ReportService.uploadFileForReport(context.getters['reportType'].report_Id, data)
      if (!error && context.state.report) {
        context.state.report!.status = response.reportStatus;
      }
    },
    async updateStatus(context: ActionContext<ReportState, RootState>, status: string) {
      if (context.state.report == undefined) return;
      const [response, error] = await ReportService.updateStatusForReport(context.state.report.report_Id, { Status: status });
      if (!error) {
        context.state.report.status = status;
      }
    },
    async fetchDemandList(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await DemandService.fetchDemandListById(context.getters['reportType'].report_Id)
      if (error) {
        return;
      }
      context.commit('setDemandList', response);
    },
    async addDemands(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await DemandService.addDemandList(context.state.demandList);
      if (error) {
        return;
      }
      context.commit('setDemandList', response);
    },
    async updateDemandList(context: ActionContext<ReportState, RootState>) {
      await DemandService.updateDemandList(context.state.demandList);
    },
    async fetchDemandListById(context: ActionContext<ReportState, RootState>, report_Id: number) {
      const [response, error] = await DemandService.fetchDemandListById(report_Id)
      if (error) {
        return;
      }
      context.commit('setDemandList', response);
    },
    async fetchLastFiveReports(context: ActionContext<ReportState, RootState>) {
      const [response, error] = await ReportService.getLastFiveReports();
      if (error) {
        context.commit("setRecentReports", []);
        return;
      }
      context.commit('setRecentReports', response)
    },
    async downloadReport(context: ActionContext<ReportState, RootState>, report_Id: string) {
      const [response, error] = await ReportService.getReportDownload(report_Id);
      if (!error) {
        return response;
      }
    },
    async createReportManually(context: ActionContext<ReportState, RootState>, options: { report: Report, userBrandingId: number, status: string, reportType: string }) {
      options.report.createdAt = moment().format("YYYY-MM-DDThh:mm:ss");
      options.report.status = options.status
      options.report.branding_Id = options.userBrandingId;
      options.report.reportType = options.reportType

      const [response, error] = await ReportService.createReport(options.report.reportType, options.report);
      if (!error) {
        return response
      }
    },

    async addAdditionalQuestion(context: ActionContext<ReportState, RootState>, additionalQuestion: AdditionalQuestion) {
      const [response, error] = await ReportService.postAddAdditionalQuestion(additionalQuestion);
      if (!error) {
        return response
      }
    },

    async markAdditionalQuestionWithIdAsDone(context: ActionContext<ReportState, RootState>, id: string) {
      const [response, error] = await ReportService.postMarkAdditionalQuestionWithIdAsDone(id);
      if (!error) {
        return response
      }
    },

    async getAdditionalQuestionsForReportId(context: ActionContext<ReportState, RootState>, report_Id: string) {
      const [response, error] = await ReportService.getAdditionalQuestionsForReportId(report_Id);
      if (!error) {
        context.commit("setAdditionalQuestions", response);
      }
    },

    async forwardReportAsMail(context: ActionContext<ReportState, RootState>, options: { reportId: number, email: string, senderEmail: string, subject: string }): Promise<boolean> {
      const [response, error] = await ReportService.forwardReportAsMail(options.reportId, options.email, options.senderEmail, options.subject);
      if (!error) {
        return true;
      } else {
        return false;
      }
    },

    async fetchAssociatedWaybills(context: ActionContext<ReportState, RootState>) {
      if (context.state.report == undefined) return;
      const waybillNum = (context.state.report as WaybillCheck).waybill_Id;
      const reportNum = context.state.report.report_Id;

      context.state.loadingAssociatedWaybills = true;
      const [response, error] = await ReportService.getAssociatedWaybillChecks(reportNum, waybillNum);
      if (!error) {
        context.commit('setAssociatedWaybills', response);
      }

      context.state.loadingAssociatedWaybills = false;
    },

    async downloadReportsResultCSV(context: ActionContext<ReportState, RootState>) {
      if (!context.state.reportFilter)
        return;

      const [response, error] = await ReportService.downloadReportsResultsCSV(context.state.reportFilter);

      if (!error) {
        const blob = new Blob([response], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        window.open(url);
      }
    },

    async hsExport() {
      const [response, error] = await ReportService.hsExport();

      if (!error) {
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);

        window.open(url);
      }
      else {
        // console.error("Error::", error);
      }
    }
  }
}

export default report;
