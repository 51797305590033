import base from "./base"
import autohausbrunkhorstgmbh from "./brunkhorst";
import autohauswillyernstgmbh from "./ernstdigital";
import lukassek from "./lukassek";
import autoveritas from "./autoveritas";
import salomongmbhversicherungsmakler from "./salomondigital"
import ohlaautomobilegmbh from "./ohladigital";
import elmatic from "./elmaticdigital";
import consaleeuroassekuranz from "./consaleeuroassekuranz";
import svrettinger from "./svrettinger";
import schlienztours from "./schlienztours";
import bob from "./bob";
import kfzspot from "./kfzspot";
import claimore from "./claimore";
import salomon from "./salomon";
import toha from "./toha";
import bfe from "./bfe";
import choice from "./choice";
import fraikin from "./fraikin";
import weinreich from "./weinreich";
import frigosped from "./frigosped";
import wahl from "./wahl";

import { IThemes } from "./utils"
import fleetloop from './fleetloop';

export const DEFAULT_THEME = "base";

export const themes: IThemes = {
    base,
    autohausbrunkhorstgmbh,
    autohauswillyernstgmbh,
    lukassek,
    autoveritas,
    salomongmbhversicherungsmakler,
    ohlaautomobilegmbh,
    elmatic,
    consaleeuroassekuranz,
    svrettinger,
    bob,
    schlienztours,
    kfzspot,
    claimore,
    salomon,
    toha,
    bfe,
    choice,
    fraikin,
    weinreich,
    frigosped,
    fleetloop,
    wahl
}